import "./styles.scss";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import landingPhone from "../../assets/images/landingPhone.png";
import googleDark from "../../assets/images/googleDark.png";

const LandingPage = () => {
  return (
    <div className="landingContainer">
      <Container style={{ height: "100%" }}>
        <Row
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          className="landingRow"
        >
          <Col lg={8} md={8} sm={12} className="contextWrapper">
            <div className="textContentArea">
              <p>
                World's first <span className="textGradiant">super app</span>{" "}
                for farmers
              </p>
              <div className="description">
                <p>Get products and services delivered in one day</p>
              </div>
              <a
                href="https://play.google.com/store/apps/details?id=com.easefarming.agri&pli=1"
                target="_blank"
                rel="noreferrer"
              >
                <img width={148} src={googleDark} alt="googleDark" />{" "}
              </a>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} className="imageWrapper">
            <div className="imgContentArea">
              <img src={landingPhone} alt="landingPhone" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LandingPage;
