import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./styles.scss";
import scene from "../../assets/images/scene.png";
import vector from "../../assets/images/Vector1.png";

const Component2 = () => {
  return (
    <div className="component2Container">
      <img width="60%" className="vectorbg" src={vector} alt="bg" />
      <Container>
        <Row
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <Col lg={12} sm={12}>
            <p className="superscript">
              We understand the challenges of farming
            </p>
            <div className="mainheadingWrapper">
              <p className="mainheading">
                Limited access to{" "}
                <span className="textGradiant">farming products</span> and{" "}
                <span className="textGradiant">services</span>
              </p>
            </div>

            <div className="subscriptWrapper">
              <p className="subscript">
                Farmers face numerous challenges, including difficulty accessing
                the products and services they need to run their farms
                efficiently. This can result in long delivery times, high
                prices, and reduced productivity, hindering the growth and
                success of their farms.
              </p>
            </div>
            <img className="sceneImg" width="100%" src={scene} alt="Scene" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Component2;
