import React from "react";
import logo from "../../assets/images/logo.png";
import "./styles.scss";
import { Col, Container, Row } from "react-bootstrap";

const Navigation = () => {
  return (
    <div className="navigationContainer container">
      <Container>
        <Row>
          <Col lg={6} sm={12} className="logoWrapper">
            <img src={logo} alt="EaseFarm logo" />
          </Col>
          <Col lg={6} sm={12} className="tabsContainer">
            <a className="tab" href="#productsNservices">
              <p>Products</p>
            </a>
            <a className="tab" href="#productsNservices">
              <p>Services</p>
            </a>
            <a className="tab" href="#contact">
              <p>Contact Us</p>
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Navigation;
