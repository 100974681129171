import React, { useState, useEffect, useRef } from "react";
import "./styles.scss";
import { Container, Row, Col } from "react-bootstrap";
import pesti from "../../assets/images/pesti.png";
import herbi from "../../assets/images/herbicide.png";
import micro from "../../assets/images/micronutri.png";
import fungi from "../../assets/images/fungicide.png";
import ferti from "../../assets/images/fungicide.png";
import biosm from "../../assets/images/biosimulant.png";
import prodList from "../../assets/images/prodList.png";
import harvesters from "../../assets/images/harvesters.png";
import pesticides from "../../assets/images/pesticides.png";
import drones from "../../assets/images/drones.png";
import seeds from "../../assets/images/seeds.png";
import harvest from "../../assets/images/harvest.png";
import spraying from "../../assets/images/spraying.png";
import BgElement from "./bgEle";

const Categories = [
  { img: pesti, name: "Pesticides" },
  { img: herbi, name: "Herbicides" },
  { img: micro, name: "Micro-nutrients" },
  { img: fungi, name: "Fungicide" },
  { img: ferti, name: "Fertilizers" },
  { img: biosm, name: "Bio Stimulant" },
];

const Services = [
  {
    img: harvest,
    title: "Harvester",
    desc: "Get your crops cut efficiently with our advacnced harvesters.Our skilled workers use modern techniques to ensure the best results.",
  },
  {
    img: spraying,
    title: "Spraying",
    desc: "Protect your crops from pests and diseases with our reliable spraying machinery . Our team uses top-quality machinery to provide effective solutions.",
  },
];

const CardDetails = [
  {
    head: "One-stop solution for all your farming needs",
    desc: "Get access to everything you need, all in one place",
  },
  {
    head: "Wide range of products",
    desc: "Find variety in category of products you need to grow your farm",
  },
  {
    head: "Affordable prices on agri services",
    desc: "Save up to 50% on your farming expenses",
  },
];

const Card = ({ head, desc, isActive, handleActiveCard, index }) => {
  return (
    <div
      onClick={() => handleActiveCard(index)}
      className={`psCard ${isActive ? "active" : ""}`}
    >
      <p className="head">{head}</p>
      <p className="desc">{desc}</p>
    </div>
  );
};

const Component3 = () => {
  const [activeCard, setActiveCard] = useState(0);
  let realSec = useRef(0);
  const handleActiveCard = (index) => {
    setActiveCard(index);
    realSec.current = index;
  };

  useEffect(() => {
    setInterval(() => {
      if (realSec.current === 2) {
        realSec.current = 0;
      } else {
        realSec.current++;
      }
      setActiveCard(realSec.current);
    }, 7000);
  }, []);

  return (
    <div id="productsNservices" className="component3container">
      <Container>
        <Row
          data-aos="fade-up"
          data-aos-delay="500"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <Col lg={12} sm={12}>
            <div className="headingWrapper">
              <p className="heading3">
                Streamlined solutions for all your farming needs
              </p>
            </div>
          </Col>
          <Col
            lg={6}
            md={6}
            sm={12}
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <BgElement
              styles={{
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: -1,
                height: "100%",
              }}
            />
            {CardDetails.map((card, i) => {
              return (
                <Card
                  key={i}
                  index={i}
                  head={card.head}
                  desc={card.desc}
                  isActive={i === activeCard}
                  handleActiveCard={handleActiveCard}
                />
              );
            })}
          </Col>
          <Col lg={6} md={6} sm={12} className="detailsSection">
            {activeCard === 0 ? (
              <div className="card1Details">
                <div className="card1ImgWrapper" id="pesticides">
                  <img
                    width="100%"
                    className="card1Img"
                    src={pesticides}
                    alt="pesticides"
                  />
                  <span>Pesticides</span>
                </div>
                <div className="card1ImgWrapper" id="seeds">
                  <img
                    className="card1Img"
                    width="100%"
                    src={seeds}
                    alt="seeds"
                  />
                  <span>Seeds</span>
                </div>
                <div className="card1ImgWrapper" id="drones">
                  <img
                    className="card1Img"
                    width="100%"
                    src={drones}
                    alt="drones"
                  />
                  <span>Drones</span>
                </div>
                <div className="card1ImgWrapper" id="harvesters">
                  <img
                    width="100%"
                    className="card1Img"
                    src={harvesters}
                    alt="harvesters"
                  />
                  <span>Harvesters</span>
                </div>
              </div>
            ) : null}
            {activeCard === 1 ? (
              <div className="card2Details">
                <div className="categoriesWrapper">
                  {Categories.map((item, i) => (
                    <div key={i} className="categoryItem">
                      <img src={item.img} alt={item.name} />
                      <p>{item.name}</p>
                    </div>
                  ))}
                </div>
                <div className="productListWrapper">
                  <img width="70%" src={prodList} alt="" />
                </div>
              </div>
            ) : null}
            {activeCard === 2 ? (
              <div className="card3Details">
                <div className="servicesWrapper">
                  {Services.map((item, i) => (
                    <div key={i} className="serviceItem">
                      <img src={item.img} alt={item.name} />
                      <div className="card3CardContent">
                        <p className="heading">{item.title}</p>
                        <p className="desc">{item.desc}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Component3;
