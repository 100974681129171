import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./styles.scss";
import illustration from "../../assets/images/illustration.png";
import googleDark from "../../assets/images/googleDark.png";

const Component4 = () => {
  return (
    <div className="component4Container">
      <Container>
        <Row
          data-aos="fade-up"
          data-aos-delay="500"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <Col lg={6}>
            <div className="heroTextWrapper">
              <p className="heroHext">
                <span className="textGradiant">Accessible</span> and{" "}
                <span className="textGradiant">affordable</span> farming
                products and services with EaseFarming App
              </p>
              <a
                href="https://play.google.com/store/apps/details?id=com.easefarming.agri&pli=1"
                target="_blank"
                rel="noreferrer"
              >
                <img width={148} src={googleDark} alt="googleDark" />
              </a>
            </div>
          </Col>
          <Col lg={6}>
            <div className="heroImgContainer">
              <img width="100%" src={illustration} alt="illustration" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Component4;
