import "./App.scss";
import Navigation from "./components/Navigation";
import LandingPage from "./components/LandingPage";
import Component2 from "./components/component2";
import Component3 from "./components/component3";
import Component4 from "./components/component4";
import ContactUs from "./components/contactUs";
// importing aos
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";

function App() {
  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <div className="App">
      <Navigation />
      <LandingPage />
      <Component2 />
      <Component3 />
      <Component4 />
      <ContactUs />
    </div>
  );
}

export default App;
